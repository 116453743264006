import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { PaymentsContext } from 'contexts/Payments';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { Spinner } from 'assets/milo';

import styles from './EarningsBanner.module.css';

const EarningsBanner = () => {
  const paymentsContext = useContext(PaymentsContext);
  const paymentsData = paymentsContext?.paymentsData || []; // Now an array for Flex currency

  const {
    formatCurrency,
    currencySymbolMap,
    supportedCountries
  } = useContext(SupportedCountriesContext);
  const { t } = useTranslation();

  return (
    <div className={styles.earningsBannerContainer}>
      {paymentsData.map((payment) => {
        const completedPaymentsCount = payment.completedPayments?.length || 0;
        return (
          <div key={payment.id} className={styles.earningsBanner}>
            <h4>
              {completedPaymentsCount} {completedPaymentsCount === 1 ? t('dashboard.paymentReceived') : t('dashboard.paymentsReceived')}
            </h4>
            <div className={styles.total}>
              {payment.currency && currencySymbolMap[payment.currency] ? (
                <span className={styles.currencyAmount}>
                  {(payment.yearToDateTotal || 0) > 0 ? (
                    <CountUp
                      end={payment.yearToDateTotal || 0}
                      duration={1.5}
                      formattingFn={(amount) => formatCurrency(amount, payment.currency!)}
                    />
                  ) : (
                    formatCurrency(payment.yearToDateTotal, payment.currency!)
                  )}
                  <span className={styles.currencyLabel}>{payment.currency}</span>
                </span>
              ) : (
                <Spinner className={styles.spinner} />
              )}
            </div>
            {payment.currency && (
              <div className={styles.bannerSubtitle}>
                {'in'} {supportedCountries.find(country => country.currencyCode === payment.currency)?.currencyName}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EarningsBanner;
