import { useContext } from 'react';
import { PaymentType } from 'utils/types/paymentType';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { formatDate } from 'utils/helpers/formatDate';
import styles from './IncomingPaymentsTable.module.css';

type Props = {
  payments: PaymentType[]
}

const DesktopTable = ({ payments }: Props) => {
  const { formatCurrency } = useContext(SupportedCountriesContext);

  return (
    <table className={styles.desktopTable}>
      <tbody>
        {
          payments.map((payment) => (
            <tr key={payment.id} className={styles.paymentRow}>
              <td className={styles.dateCol}>{formatDate(payment.deliveryDateRangeStart)} - {formatDate(payment.deliveryDateRangeEnd)}</td>
              <td className={styles.companyCol} data-dd-privacy='mask'>{payment.companyName}</td>
              <td className={styles.usdAmountCol}>
                <div className={styles.currencyContainer}>
                  <div>{formatCurrency(payment.details.collectionAmount, payment.details.collectionCurrency)}</div>
                  <div className={styles.currencyLabel}>{payment.details.collectionCurrency}</div>
                </div>
              </td>
              <td className={styles.localAmountCol}>
                <div className={styles.currencyContainer}>
                  <div>{formatCurrency(payment.details.disbursementAmountActual || 0, payment.details.disbursementCurrency)}</div>
                  <div className={styles.currencyLabel}>{payment.details.disbursementCurrency}</div>
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
}

export default DesktopTable;
