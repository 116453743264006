import { useContext } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import { Spinner } from 'assets/milo';
import { sortPaymentsByDate } from 'utils/helpers/sortPaymentsByDate';
import PaymentsHistoryPlaceholder from './PaymentsHistoryPlaceholder';
import styles from './PaymentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';
import { useTranslation } from 'react-i18next';
import { LD_FLAGS } from 'constants/launchdarklyFlags';
import { useFlagValue } from 'hooks/useFeatureFlags';
import ExportPayHistoryButton from '../ExportPayHistoryButton';

const PaymentsTable = () => {
  const paymentsContext = useContext(PaymentsContext);
  const { t } = useTranslation();
  const isExportCsvEnabled = useFlagValue(LD_FLAGS.exportCsv);

  // Combine all completed payments from multiple payment groups
  const completedPayments = paymentsContext?.paymentsData
    ?.flatMap(paymentGroup => paymentGroup.completedPayments || [])
    .filter(payment => payment.details.transferSettledAt && payment.details.disbursementAmountActual);

  // Sort the combined completed payments by date
  const sortedCompletedPayments = sortPaymentsByDate(completedPayments);

  // Get the single currency assumed to be used across all payment groups
  const currency = paymentsContext?.paymentsData?.[0]?.currency;

  if (!currency) {
    return <Spinner className={styles.spinner} />;
  }

  const paymentHistoryTableTitle = (
    <div className={styles.tableTitle}>
      {t('dashboard.paymentHistory.title1')}
    </div>
  );

  return sortedCompletedPayments && sortedCompletedPayments.length > 0 ? (
    <div className={styles.paymentsTable}>
      {isExportCsvEnabled ? (
        <div className={styles.tableTitleContainer}>
          {paymentHistoryTableTitle}
          <ExportPayHistoryButton data={sortedCompletedPayments} />
        </div>
      ) : (
        paymentHistoryTableTitle
      )}
      <MobileTable completedPayments={sortedCompletedPayments} />
      <DesktopTable completedPayments={sortedCompletedPayments} />
    </div>
  ) : (
    <PaymentsHistoryPlaceholder />
  );
};

export default PaymentsTable;
