import React, { useContext, useState, useEffect } from 'react';
import styles from './ExportPayHistoryButton.module.css';
import { Button, Dialog } from '@justworkshr/milo-core';
import {
  useCSVDownloader,
  usePapaParse
} from 'react-papaparse';
import { SupportedCountriesContext } from 'contexts/SupportedCountries';
import { formatDate } from 'utils/helpers/formatDate';
import { GET_CURRENT_MEMBER_DETAILS_QUERY_NAME, QUERY_CURRENT_MEMBER_DETAILS } from 'graphql/queries/MemberDetailQueries';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import { PaymentType } from 'utils/types/paymentType';

interface ExportPayHistoryButtonProps {
  data: PaymentType[]
}

const ExportPayHistoryButton: React.FC<ExportPayHistoryButtonProps> = ({ data }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { jsonToCSV } = usePapaParse();

  const { data: memberData } = useQueryWithErrors(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const fullName = `${memberData?.currentMemberDetails?.firstName} ${memberData?.currentMemberDetails?.lastName}`;

  const { formatCurrency } = useContext(SupportedCountriesContext);

  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [hasError, setHasError] = useState(false);
  const [csvData, setCsvData] = useState<string | undefined>(undefined);

  const normalizeToUtcMidnight = (dateStr: string) => {
    // Parse the date string and set time to midnight UTC
    const [year, month, day] = dateStr.split('T')[0].split('-').map(Number);
    return new Date(Date.UTC(year, month - 1, day)); // Create UTC date at 00:00:00
  };

  const generateCSVData = (payments: PaymentType[], fromDate?: string, toDate?: string): string | undefined => {
    const filteredPayments = payments.filter(payment => {
      if (!payment.details.disbursementSettledAt) return false;

      // Normalize the disbursementSettledAt and comparison dates to UTC
      const paymentDate = normalizeToUtcMidnight(payment.details.disbursementSettledAt);

      const fromDateUtc = fromDate ? normalizeToUtcMidnight(fromDate) : null;
      const toDateUtc = toDate ? normalizeToUtcMidnight(toDate) : null;

      if (fromDateUtc && paymentDate < fromDateUtc) return false;
      if (toDateUtc && paymentDate > toDateUtc) return false;

      return true;
    });

    if (filteredPayments.length === 0) {
      setHasError(true);
      return undefined;
    }

    const json = filteredPayments.map(payment => ({
      'Paying Company Name': payment.companyName,
      'Contractor Name': fullName,
      'Debit Amount': formatCurrency(payment.details.collectionAmount, payment.details.collectionCurrency),
      'Debit Currency': payment.details.collectionCurrency.toUpperCase(),
      'Credit Amount': formatCurrency(payment.details.disbursementAmountActual || 0, payment.details.disbursementCurrency),
      'Credit Currency': payment.details.disbursementCurrency.toUpperCase(),
      'Arrival Date': formatDate(payment.details.disbursementSettledAt)
    }));

    setHasError(false);
    return jsonToCSV(json);
  };

  useEffect(() => {
    if (fromDate && toDate) {
      const csv = generateCSVData(data, fromDate, toDate);
      setCsvData(csv);
    } else {
      setCsvData(undefined);
    }
  }, [fromDate, toDate]);

  const clearExportModalState = () => {
    setIsOpen(false);
    setFromDate('');
    setToDate('');
    setCsvData(undefined);
    setHasError(false);
  };

  return (
    <>
      <div className={styles.exportButtonContainer}>
        <Button variant='outlined' size='sm' onClick={() => setIsOpen(true)}>Export</Button>
      </div>

      <Dialog
        title={'Pay history export'}
        isOpen={isOpen}
        size='sm'
        showCloseButton={true}
        onClose={() => clearExportModalState()}
        primaryButton={
          <Button variant='filled' disabled={!csvData || !fromDate || !toDate}>
            <CSVDownloader
              type={Type.Link}
              filename={`Justworks-${fullName}-pay history`}
              data={csvData || ''}
            >
              Export pay history
            </CSVDownloader>
          </Button>
        }
        secondaryButton={
          <Button variant='ghost' onClick={() => clearExportModalState()}>
            Cancel
          </Button>
        }
      >
        <>
          Export a CSV file of your selected pay history.
          <div className={styles.datePickersContainer}>
            <div>
              <label htmlFor='fromDate'>Pay Date Start</label>
              <input
                type='date'
                id='fromDate'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className={hasError ? styles.error : ''}
              />
            </div>
            <div>
              <label htmlFor='toDate'>Pay Date End</label>
              <input
                type='date'
                id='toDate'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className={hasError ? styles.error : ''}
              />
            </div>
            {hasError && (
              <div className={styles.datePickersError}>
                No pay history found. Please select a different range.
              </div>
            )}
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ExportPayHistoryButton;
