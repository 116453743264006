import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import usePageTitle from 'hooks/usePageTitle';
import BankAccountForm from 'components/Forms/BankAccountForm';
import VerticalStepper from 'components/VerticalStepper';
import OnboardingSuccessAlert from './components/OnboardingSuccessAlert';
import { Spinner } from 'assets/milo';
import { useAppAlerts, AlertTypes } from 'contexts/AppAlerts';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import {
  GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME,
  QueryCurrentCompanyMembersResType,
  QUERY_CURRENT_COMPANY_MEMBERS
} from 'graphql/queries/CompanyMemberQueries';
import { OnboardingContext } from 'contexts/Onboarding'

import styles from '../Onboarding.module.css';
import { GET_MEMBER_ONBOARDING_QUERY_NAME, QUERY_MEMBER_ONBOARDING } from 'graphql/queries/MemberOnboardingQueries';
import { personaEnabled } from 'utils/helpers/flags';
import { OnboardingSteps, mapWorkflowToEnum } from '../utils/constants';
import { PageHeader } from '@justworkshr/milo-core';

const OnboardingPayment = () => {
  const { t } = useTranslation();
  usePageTitle(t('onboarding.onboarding'));

  const { pushAlerts } = useAppAlerts();
  const navigate = useNavigate();

  const { data, loading } = useQueryWithErrors(
    GET_MEMBER_ONBOARDING_QUERY_NAME,
    QUERY_MEMBER_ONBOARDING,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const workflowState = data?.memberOnboarding?.details?.workflowState
  useEffect(() => {
    if (workflowState === 'complete') {
      navigate('/dashboard');
    }
  }, [data])

  const onboardingContext = useContext(OnboardingContext)
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);

  const backStep = () => {
    navigate('/onboarding/personal-info');
  };

  const handleStepClick = (stepNumber:number) => {
    if (stepNumber === OnboardingSteps.personalInfo) {
      navigate('/onboarding/personal-info');
    }
    if (stepNumber === OnboardingSteps.persona) {
      navigate('/onboarding/persona-verification');
    }
  }

  useQueryWithErrors<QueryCurrentCompanyMembersResType>(
    GET_CURRENT_COMPANY_MEMBERS_QUERY_NAME,
    QUERY_CURRENT_COMPANY_MEMBERS,
    {
      skip: !onboardingComplete,
      onCompleted: (data) => {
        const latestCompanyMember = data.currentCompanyMembers.filter((cm) => (
          cm.details.status !== 'inactive'
        )).sort((cm1, cm2) => (
          (new Date(cm2.details.createdAt)).valueOf() - (new Date(cm1.details.createdAt)).valueOf()
        ));

        const companyName = latestCompanyMember[0].company.details.name

        const alert = {
          message: <OnboardingSuccessAlert companyName={companyName} />,
          type: AlertTypes.Success,
          doNotClearOnLocations: ['/', '/dashboard']
        }
        pushAlerts([alert]);
        navigate('/dashboard?onboarding_complete=true');
        window.scrollTo(0, 0);
      },
      onError: () => {
        navigate('/dashboard');
        window.scrollTo(0, 0);
      }
    }
  )
  const finish = () => {
    onboardingContext?.setIsOnboarded(true)
    setOnboardingComplete(true)
  };

  const nextStep = () => {
    personaEnabled() ? navigate('/onboarding/persona-verification') : finish();
  }

  if (loading) { return <Spinner variation='black' /> }

  return (
    <div className={styles.content}>
      <div className={styles.container} data-testid='onboarding-container'>
        <aside>
        <VerticalStepper
            steps={[
              t('onboarding.yourProfile'),
              t('onboarding.paymentInfo'),
              t('onboarding.persona')
            ]}
            currentStep={OnboardingSteps.bankingInfo}
            onClick={handleStepClick}
            status={mapWorkflowToEnum[workflowState]}
          />
        </aside>
        <div>
          <PageHeader
            className={styles.pageHeader}
            title={t('onboarding.enterPaymentInfo')}
            border={false}
          >
            { t('bankAccount.bankAccountNotice') }
          </PageHeader>
          <BankAccountForm
            onBack={backStep}
            onCompleted={nextStep}
            submitButtonText={t('onboarding.saveAndContinue')}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingPayment;
