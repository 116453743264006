import { useState, createContext, Dispatch, SetStateAction } from 'react';

import { PaymentType } from 'utils/types/paymentType';

type PaymentsDataType = {
  id: string;
  yearToDateTotal?: number;
  completedPayments?: PaymentType[];
  incomingPayments?: PaymentType[];
  currency?: string;
};

export type PaymentsContextType = {
  paymentsData: PaymentsDataType[];
  setPaymentsData: Dispatch<SetStateAction<PaymentsDataType[]>>;
};

export const PaymentsContext = createContext<PaymentsContextType | null>(null);

export const PaymentsContextProvider = ({ children }: { children: JSX.Element }) => {
  // Initialize as an array with at least one default object
  const [paymentsData, setPaymentsData] = useState<PaymentsDataType[]>([
    {
      id: 'default',
      yearToDateTotal: 0,
      completedPayments: [],
      incomingPayments: [],
      currency: undefined
    }
  ]);

  return (
    // the Provider gives access to the context to its children
    <PaymentsContext.Provider value={{ paymentsData, setPaymentsData }}>
      {children}
    </PaymentsContext.Provider>
  );
};
