import DocumentsTable from './components/DocumentsTable';
import styles from './Documents.module.css'
import useDocuments from 'hooks/useDocuments';

const Documents = () => {
  useDocuments();

  return (
    <div className={styles.content}>
      <DocumentsTable />
    </div>
  );
};

export default Documents;
