import { useContext, useEffect } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import useQueryWithErrors from 'hooks/useQueryWithErrors';
import {
  GET_ALL_PAYMENT_HISTORY_QUERY_NAME,
  PaymentHistoryGqlResponseType,
  QUERY_ALL_PAYMENTS
} from 'graphql/queries/PaymentQueries';

const usePayments = () => {
  const paymentsContext = useContext(PaymentsContext);
  const { data } = useQueryWithErrors<PaymentHistoryGqlResponseType | PaymentHistoryGqlResponseType[]>(
    GET_ALL_PAYMENT_HISTORY_QUERY_NAME,
    QUERY_ALL_PAYMENTS
  );

  useEffect(() => {
    if (!data) return;

    const normalizePaymentCurrencyGroup = (paymentData: PaymentHistoryGqlResponseType, index: number) => ({
      id: `payment-currency-group-${index}`,
      currency: paymentData.paymentsYearToDateTotal.currency,
      yearToDateTotal: paymentData.paymentsYearToDateTotal.value,
      completedPayments: paymentData.allOrSelectedPayments.filter(p => p.details.status === 'completed_success'),
      incomingPayments: paymentData.allOrSelectedPayments.filter(p => p.details.status !== 'completed_success')
    });

    const newPaymentsData = (
      Array.isArray(data) ? data.map(normalizePaymentCurrencyGroup) : [normalizePaymentCurrencyGroup(data, 0)]
    );

    paymentsContext?.setPaymentsData(newPaymentsData);
  }, [data]);

  return paymentsContext?.paymentsData || [];
};

export default usePayments;
