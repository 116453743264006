import { useContext } from 'react';
import { PaymentsContext } from 'contexts/Payments';
import { Spinner } from 'assets/milo';
import { sortIncomingPaymentsByDate } from 'utils/helpers/sortPaymentsByDate';
import styles from './IncomingPaymentsTable.module.css';
import MobileTable from './MobileTable';
import DesktopTable from './DesktopTable';

const IncomingPaymentsTable = () => {
  const paymentsContext = useContext(PaymentsContext);

  // Combine all incoming payments from multiple payment groups
  const allIncomingPayments = paymentsContext?.paymentsData?.flatMap(paymentGroup => paymentGroup.incomingPayments || []);

  // Sort them by date
  const sortedIncomingPayments = sortIncomingPaymentsByDate(allIncomingPayments);

  // Get the first currency from paymentsData (since we assume only one currency is used)
  const currency = paymentsContext?.paymentsData?.[0]?.currency;

  if (!currency) {
    return <Spinner className={styles.spinner} />;
  }

  return sortedIncomingPayments && sortedIncomingPayments.length > 0 ? (
    <div className={styles.paymentsTable}>
      <div className={styles.tableTitle}>Upcoming payments</div>
      <MobileTable payments={sortedIncomingPayments} />
      <DesktopTable payments={sortedIncomingPayments} />
    </div>
  ) : null;
};

export default IncomingPaymentsTable;
